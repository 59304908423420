<template>
  <b-overlay
    :show="$apollo.loading"
    spinner-variant="primary"
  >
    <section class="grid-view">
      <b-card
        v-for="(Review, i) in Reviews"
        :key="i"
        :img-src="require('@/assets/images/slider/06.jpg')"
        :title="Review.title"
        class="ecommerce-card"
        img-alt="card img"
        img-top
        no-body
      >
        <b-card-body>
          <b-card-title>{{ Review.title }}</b-card-title>
          <b-card-sub-title>{{ Review.type }}</b-card-sub-title>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <b-link
            v-if="Review.review_status==='disabled'"
            :to="`/startup-portal/programs/review/evaluations/${$route.params.id}/${Review.id}/${$route.params.aid}`"
            class="btn btn-success btn-cart"
          >
            <span>Report</span>
          </b-link>
          <b-link
            v-else
            class="btn btn-wishlist btn-secondary"
            variant="danger"
            disabled
          >
            <span>Report</span>
          </b-link>
          <b-link
            :to="`/startup-portal/programs/review/${$route.params.id}/${Review.id}/${$route.params.aid}`"
            class="btn btn-primary btn-cart"
          >
            <span>View / Submit</span>
          </b-link>
        </div>
      </b-card>
    </section>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardBody, BCardTitle, BCardSubTitle, BOverlay, BLink,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
// import store from '@/store'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BOverlay,
    BLink,
  },
  data() {
    return {
      Reviews: [],
    }
  },
  apollo: {
    Reviews: {
      query() {
        return gql`
      {
      programs_assignmenttable(where: {progress_stage_id: {_eq: ${this.$route.params.mid} }}) {
          id
          title
          status
          review_status
          type
        }
      }`
      },
      update: data => data.programs_assignmenttable,

    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
